.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    align-items: center;
    padding: 40px 0px 80px 0px;
    
    @media (max-width: 960px) {
        padding: 0px;
    }
}
.firsttitle{
    font-size: 42px;
text-align: center;
font-weight: 600;
margin-top: 20px;
margin-bottom: 20px;
  color: black;
  @media (max-width: 768px) {
      margin-top: 12px;
      font-size: 32px;
  }
}

.card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 450px;
    display: flex;
    flex-direction: column;
    align-self: center;
    transition: all 0.3s ease-in-out;
    border: 0.1px solid #854CE6;
    @media only screen and (max-width: 768px){
      padding: 10px;
      gap: 8px;
      width: 300px;
      margin-bottom: 100px;
  }
  }
  .card:hover{
    box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
    transform: translateY(-5px);
  }
  
  .title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }
  
  .form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .group {
    position: relative;
  }
  
  .form .group label {
    font-size: 14px;
    color: rgb(99, 102, 102);
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    transition: all .3s ease;
  }
  
  .form .group input,
  .form .group textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    outline: 0;
    width: 100%;
    background-color: transparent;
  }
  
  .form .group input:placeholder-shown+ label, .form .group textarea:placeholder-shown +label {
    top: 10px;
    background-color: transparent;
  }
  
  .form .group input:focus,
  .form .group textarea:focus {
    border-color: blueviolet;
  }
  
  .form .group input:focus+ label, .form .group textarea:focus +label {
    top: -10px;
    left: 10px;
    background-color: #fff;
    color: blueviolet;
    font-weight: 600;
    font-size: 14px;
  }
  
  .form .group textarea {
    resize: none;
    height: 100px;
  }
  
  .form button {
    background-color:blueviolet;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .form button:hover {
    background-color: #3c096c;
  }
  
  